'use client'

import React, { createContext, useContext, useState, useCallback } from 'react';
import getAiResponseWithFetch from '../utils/getAiResponseWithFetch';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);

  const addMessage = useCallback((message) => {
    setMessages(prev => [...prev, message]);
  }, []);

  const updateLastMessage = useCallback((content) => {
    setMessages(prev => {
      const lastMessage = prev[prev.length - 1];
      if (lastMessage.role === 'assistant') {
        return [...prev.slice(0, -1), { ...lastMessage, content: lastMessage.content + content }];
      }
      return [...prev, { role: 'assistant', content }];
    });
  }, []);

  const generateResponse = useCallback(async (input) => {
    if (!input.trim() || isGenerating) return;

    setIsGenerating(true);
    const userMessage = { role: 'user', content: input };
    addMessage(userMessage);

    try {
      // 将所有之前的消息和新的用户消息一起传递给 API
      const allMessages = [...messages, userMessage];
      await getAiResponseWithFetch(allMessages, updateLastMessage);
    } catch (error) {
      console.error('Error generating AI response:', error);
      addMessage({ role: 'assistant', content: '抱歉，生成回复时出现错误。' });
    } finally {
      setIsGenerating(false);
    }
  }, [isGenerating, addMessage, updateLastMessage, messages]);

  return (
    <ChatContext.Provider value={{ messages, isGenerating, generateResponse }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);