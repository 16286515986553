const API_URL = "https://api.siliconflow.cn/v1/chat/completions";
const API_KEY = "sk-jgkoisuciekrvvjrkpwuwpxuasineeckvkxjhzeaxlzmwzwc";
const MODEL = "Qwen/Qwen2.5-7B-Instruct";
const STREAM_FLAG = true;
const MAX_TOKENS = 4096;

let controller = null;

const getAiResponseWithFetch = async (messages, setResult) => {
  if (messages.length === 0) {
    throw new Error("请输入提示。");
  }

  controller = new AbortController();
  const signal = controller.signal;

  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        model: MODEL,
        messages: messages,
        max_tokens: MAX_TOKENS,
        stream: STREAM_FLAG,
      }),
      signal,
    });

    if (!response.ok) {
      throw new Error(`HTTP 错误！状态：${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let buffer = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      buffer += decoder.decode(value, { stream: true });
      const lines = buffer.split('\n');
      buffer = lines.pop();

      for (const line of lines) {
        const trimmedLine = line.trim();
        if (trimmedLine === '') continue;
        if (trimmedLine === 'data: [DONE]') break;
        if (trimmedLine.startsWith('data: ')) {
          try {
            const parsedLine = JSON.parse(trimmedLine.slice(5));
            if (parsedLine.choices && parsedLine.choices[0] && parsedLine.choices[0].delta) {
              const { content } = parsedLine.choices[0].delta;
              if (content) {
                setResult(content);
              }
            }
          } catch (e) {
            console.error("解析 JSON 时出错:", trimmedLine);
          }
        }
      }
    }
  } catch (error) {
    if (signal.aborted) {
      console.log("请求已中止。");
    } else {
      console.error("错误:", error);
      throw error;
    }
  } finally {
    controller = null;
  }
};

getAiResponseWithFetch.stop = () => {
  if (controller) {
    controller.abort();
    controller = null;
  }
};

export default getAiResponseWithFetch;