import { ChatProvider } from './context/ChatContext';
import AIChat from "./components/AIChat";

function App() {
  return (
    <ChatProvider>
      <div className="h-screen w-screen">
        <AIChat />
      </div>
    </ChatProvider>
  );
}

export default App;